const CRM = {

  'E18_Physical_Thing': 'crm:E18 Physical Thing',
  'E21_Person': 'crm:E21 Person',
  'E74_Group': 'crm:E74 Group',

  'OA8_begins_in': 'crm:OA8 begins in',
  'OA9_ends_in': 'crm:OA9 ends in',

  'P53_has_former_or_current_location': 'crm:P53 has former or current location',
  'P74_has_current_or_former_residence': 'crm:P74 has current or former residence'

}

export default CRM;